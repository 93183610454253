@if (currentTourStep) {
    <div>
        @if (!selectedElementRect) {
            <div>
                <div class="guided-tour-overlay" (click)="backdropClick($event)"></div>
            </div>
        } @else {
            <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.TOP) || null" (click)="backdropClick($event)"></div>
            <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.LEFT) || null" (click)="backdropClick($event)"></div>
            <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.RIGHT) || null" (click)="backdropClick($event)"></div>
            <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.BOTTOM) || null" (click)="backdropClick($event)"></div>
            <div
                class="guided-tour-element-overlay"
                [class.click-through]="currentTourStep.userInteractionEvent"
                [ngStyle]="getOverlayStyle(OverlayPosition.ELEMENT) || null"
                (click)="backdropClick($event)"
            ></div>
        }
    </div>
}
@if (currentTourStep) {
    <div>
        @if (currentTourStep) {
            <div
                #tourStep
                class="tour-step tour-{{ orientation }}"
                [class.page-tour-step]="!currentTourStep.highlightSelector"
                [class.startFade]="startFade"
                [class.video-tour]="isVideoTourStep()"
                [style.top.px]="topPosition"
                [style.left.px]="leftPosition"
                [style.width.px]="calculatedTourStepWidth"
                [style.transform]="transform"
            >
                @if (currentTourStep.highlightSelector) {
                    <div class="tour-arrow"></div>
                }
                <div class="tour-block">
                    <div class="tour-block__header">
                        @if (currentTourStep.headlineTranslateKey) {
                            <h3 class="headline">
                                @if (!guidedTourService.isOnResizeMessage && guidedTourService.currentTour && guidedTourService.currentTour.steps.length > 1) {
                                    <span>{{ 'tour.step' | artemisTranslate: { string: guidedTourService.getCurrentStepString() } }} </span>
                                }
                                {{ currentTourStep.headlineTranslateKey | artemisTranslate }}
                            </h3>
                        }
                        @if (guidedTourService.currentTour) {
                            <div
                                class="btn-close"
                                (click)="
                                    guidedTourService.isCurrentTour(cancelTour) || guidedTourService.isCurrentTour(completedTour)
                                        ? guidedTourService.resetTour()
                                        : guidedTourService.skipTour()
                                "
                            ></div>
                        }
                    </div>
                    <div class="tour-block__content">
                        @if (currentTourStep.subHeadlineTranslateKey) {
                            <h5 jhiTranslate="{{ currentTourStep.subHeadlineTranslateKey }}" class="sub-headline"></h5>
                        }
                        <div [innerHTML]="currentTourStep.contentTranslateKey | artemisTranslate"></div>
                        @if (currentTourStep.hintTranslateKey) {
                            <div class="step-hint">
                                <div class="step-hint__icon">
                                    <fa-icon [icon]="faInfoCircle" />
                                </div>
                                <div class="step-hint__label">
                                    <div [innerHTML]="currentTourStep.hintTranslateKey | artemisTranslate"></div>
                                </div>
                            </div>
                        }
                        @if (currentTourStep.alreadyExecutedTranslateKey) {
                            <div class="step-hint interaction alert alert-success">
                                <div class="step-hint__icon">
                                    <fa-icon [icon]="faCheck" />
                                </div>
                                <div class="step-hint__label">
                                    <div [innerHTML]="currentTourStep.alreadyExecutedTranslateKey | artemisTranslate"></div>
                                </div>
                            </div>
                        }
                        @if (currentTourStep.userInteractionEvent && !currentTourStep.modelingTask) {
                            <div class="step-hint interaction alert" [class.alert-success]="userInteractionFinished">
                                @if (this.userInteractionFinished) {
                                    <div class="step-hint__icon">
                                        <fa-icon [icon]="faCheck" />
                                    </div>
                                } @else {
                                    <div class="step-hint__icon">
                                        @if (currentTourStep.userInteractionEvent === UserInteractionEvent.CLICK) {
                                            <fa-icon [icon]="faHandPointUp" />
                                        }
                                        @if (currentTourStep.userInteractionEvent === UserInteractionEvent.ACE_EDITOR) {
                                            <fa-icon [icon]="faICursor" />
                                        }
                                        @if (currentTourStep.userInteractionEvent === UserInteractionEvent.WAIT_FOR_SELECTOR) {
                                            <fa-icon animation="spin" [icon]="faCircleNotch" />
                                        }
                                        @if (currentTourStep.userInteractionEvent === UserInteractionEvent.MODELING && !currentTourStep.modelingTask) {
                                            <fa-icon [icon]="faArrowsAlt" />
                                        }
                                        @if (currentTourStep.userInteractionEvent === UserInteractionEvent.ASSESS_SUBMISSION) {
                                            <fa-icon [icon]="faEdit" />
                                        }
                                    </div>
                                }
                                <div class="step-hint__label">
                                    @if (currentTourStep.userInteractionEvent === UserInteractionEvent.CLICK) {
                                        <span [jhiTranslate]="'tour.clickHint.text'"></span>
                                    }
                                    @if (currentTourStep.userInteractionEvent === UserInteractionEvent.ACE_EDITOR) {
                                        <span [jhiTranslate]="'tour.typeHint.text'"></span>
                                    }
                                    @if (currentTourStep.userInteractionEvent === UserInteractionEvent.WAIT_FOR_SELECTOR) {
                                        <span [jhiTranslate]="'tour.waitHint.text'"></span>
                                    }
                                    @if (currentTourStep.userInteractionEvent === UserInteractionEvent.MODELING && !currentTourStep.modelingTask) {
                                        <span [jhiTranslate]="'tour.modelingHint.text'"></span>
                                    }
                                    @if (currentTourStep.userInteractionEvent === UserInteractionEvent.ASSESS_SUBMISSION) {
                                        <span [jhiTranslate]="currentTourStep.assessmentTask.taskTranslateKey"></span>
                                    }
                                </div>
                            </div>
                        }
                        @if (currentTourStep.modelingTask) {
                            <div class="step-hint interaction alert" [class.alert-success]="userInteractionFinished">
                                @if (!userInteractionFinished) {
                                    <div class="step-hint__icon">
                                        <fa-icon [icon]="faClipboardList" />
                                    </div>
                                }
                                @if (userInteractionFinished) {
                                    <div class="step-hint__icon">
                                        <fa-icon [icon]="faCheck" />
                                    </div>
                                }
                                <div class="step-hint__label">
                                    <div [innerHTML]="currentTourStep.modelingTask.taskTranslateKey | artemisTranslate"></div>
                                </div>
                            </div>
                        }
                        @if (currentTourStep.imageUrl) {
                            <div>
                                <img src="{{ currentTourStep.imageUrl }}" />
                            </div>
                        }
                        @if (currentTourStep.videoUrl) {
                            <div class="step-hint interaction alert">
                                <div class="step-hint__icon">
                                    <fa-icon [icon]="faVideo" />
                                </div>
                                <div class="step-hint__label">
                                    <span jhiTranslate="tour.videoHint.text"></span>
                                </div>
                            </div>
                        }
                        @if (currentTourStep.videoUrl) {
                            <div>
                                <iframe [src]="currentTourStep.videoUrl | artemisTranslate | safeResourceUrl" frameborder="0" allowfullscreen></iframe>
                            </div>
                        }
                    </div>
                    <div class="tour-block__buttons">
                        @if (!guidedTourService.isCurrentTour(cancelTour) && !guidedTourService.isCurrentTour(completedTour) && !guidedTourService.isOnResizeMessage) {
                            <button class="back-button" [disabled]="guidedTourService.isOnFirstStep" (click)="guidedTourService.backStep()">
                                <fa-icon [icon]="faChevronLeft" />
                                <span jhiTranslate="tour.navigation.back"></span>
                            </button>
                        }
                        @if (guidedTourService.isCurrentTour(completedTour)) {
                            <button
                                class="restart-button"
                                [disabled]="guidedTourService.isOnResizeMessage || guidedTourService.restartIsLoading"
                                (click)="guidedTourService.restartTour()"
                            >
                                @if (!guidedTourService.restartIsLoading) {
                                    <fa-icon [icon]="faPlayCircle" />
                                }
                                @if (guidedTourService.restartIsLoading) {
                                    <fa-icon class="jhi-btn__loading" [icon]="faCircleNotch" animation="spin" size="sm" />
                                }
                                <span jhiTranslate="global.menu.restartTutorial"></span>
                            </button>
                        }
                        @if (!guidedTourService.isOnResizeMessage) {
                            <div class="dotstyle dotstyle--scaleup">
                                @if (guidedTourService.currentTour && guidedTourService.currentTour.steps.length > 1) {
                                    <ul #dotNavigation [ngStyle]="{ transform: 'translateX(' + transformX + 'px)' }">
                                        @for (i of guidedTourService.currentTour.steps; let index = $index; track index) {
                                            <li
                                                #dotElements
                                                class="dot-index-{{ guidedTourService.currentTour.steps.indexOf(i) }}"
                                                [class.current]="guidedTourService.isCurrentStep(i)"
                                                [class.n-small]="calculateNSmallDot(guidedTourService.currentTour.steps.indexOf(i) + 1)"
                                                [class.p-small]="calculatePSmallDot(guidedTourService.currentTour.steps.indexOf(i) + 1)"
                                            >
                                                <span>{{ guidedTourService.currentTour.steps.indexOf(i) }}</span>
                                            </li>
                                        }
                                    </ul>
                                }
                            </div>
                        }
                        @if (!guidedTourService.isOnLastStep && !guidedTourService.isOnResizeMessage) {
                            <button class="next-button" [disabled]="currentTourStep.userInteractionEvent && !userInteractionFinished" (click)="guidedTourService.nextStep()">
                                <span jhiTranslate="tour.navigation.next"></span>
                                <fa-icon [icon]="faChevronRight" />
                            </button>
                        }
                        @if (
                            guidedTourService.isOnLastStep &&
                            !guidedTourService.isCurrentTour(cancelTour) &&
                            !guidedTourService.isCurrentTour(completedTour) &&
                            !guidedTourService.isOnResizeMessage
                        ) {
                            <button
                                class="next-button"
                                jhiTranslate="tour.navigation.done"
                                [disabled]="currentTourStep.userInteractionEvent && !userInteractionFinished"
                                (click)="guidedTourService.nextStep()"
                            ></button>
                        }
                        @if (guidedTourService.isOnResizeMessage || guidedTourService.isCurrentTour(cancelTour) || guidedTourService.isCurrentTour(completedTour)) {
                            <button class="next-button" jhiTranslate="tour.navigation.close" (click)="guidedTourService.resetTour()"></button>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
}
