<div class="d-flex justify-content-between">
    <div>
        <h1>
            <!--UserSettingsCategory-->
            {{ 'artemisApp.userSettings.categories.' + userSettingsCategory | artemisTranslate }}
        </h1>
        <div class="d-inline-flex userSettings-info">
            <fa-icon class="ng-fa-icon" [icon]="faInfoCircle" />
            <span class="ps-1">
                <!-- Info text explaining that these settings also filter the notification sidebar -->
                {{ 'artemisApp.userSettings.notificationSettingsFilterInfo' | artemisTranslate }}
            </span>
        </div>
    </div>
    @if (settingsChanged) {
        <button type="button" class="btn btn-primary" id="apply-changes-button" (click)="this.saveSettings()">
            <fa-icon class="ng" [icon]="faSave" />
            {{ 'artemisApp.userSettings.saveChanges' | artemisTranslate }}
        </button>
    }
</div>
@if (userSettings) {
    <div class="list-group d-block">
        @for (settingGroup of userSettings.groups; track settingGroup) {
            <div>
                <div *jhiHasAnyAuthority="settingGroup.restrictionLevels" class="list-group-item">
                    <h3>
                        <!--GroupName-->
                        <!-- please look at the README.md file to understand how the translation works -->
                        {{ 'artemisApp.userSettings.settingGroupNames.' + settingGroup.key | artemisTranslate }}
                    </h3>
                    @for (setting of settingGroup.settings; track setting) {
                        <div>
                            <dt>
                                <!--SettingName-->
                                {{ 'artemisApp.userSettings.settingNames.' + setting.key | artemisTranslate }}
                            </dt>
                            <span>
                                <!--SettingDescription-->
                                {{ 'artemisApp.userSettings.settingDescriptions.' + setting.descriptionKey | artemisTranslate }}
                            </span>
                            <!--Setting Specific Properties-->
                            <div class="d-flex">
                                @if (setting.webapp !== undefined && (setting.webappSupport === undefined || setting.webappSupport)) {
                                    <div class="form-check pe-3">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            [checked]="setting.webapp"
                                            id="{{ setting.settingId }}"
                                            value="{{ setting.webapp }}"
                                            (click)="toggleSetting($event, communicationChannel.WEBAPP)"
                                        />
                                        <label class="form-check-label" for="{{ setting.settingId }}">WebApp</label>
                                    </div>
                                }
                                @if (setting.email !== undefined && setting.emailSupport) {
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            [checked]="setting.email"
                                            id="{{ setting.settingId }} email"
                                            value="{{ setting.email }}"
                                            (click)="toggleSetting($event, communicationChannel.EMAIL)"
                                        />
                                        <label class="form-check-label" for="{{ setting.settingId }} email">Email</label>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}
